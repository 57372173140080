import React, { useState } from 'react';
import { DonationBdMedium } from './DonationBdItem';
import Nagad from '../../static/icons/nagad.inline.svg';
import Bkash from '../../static/icons/bkash-logo.inline.svg';
import Rocket from '../../static/icons/rocket-logo.inline.svg';
import BankLogo from '../../static/icons/bank-logo.inline.svg';
import BkashQr from '../../static/icons/bkash-qr-pay-icon.inline.svg';
import NagadQr from '../../static/icons/nagad-qr-pay-icon.inline.svg';
import BankIcon from '../../static/icons/donation-bd-bank-icon.inline.svg';
import BankIcon2 from '../../static/icons/donation-bd-bank-icon2.inline.svg';

import { copyToClipboard } from '../utils';
import { useToast } from '../hooks/useToast';
import Icon from './ui/icon';
import CopyButton from './ui/CopyButton';
import BkashPaymentOptions from './BkashPaymentOptions';
export function DonationBdMediumList() {
  const donationBdMediums = [
    {
      name: 'nagad',
      logo: <Nagad />,
      icon: '/images/nagad-icon.png',
      icon2: '',
      icon3: '',
      title_bn: 'নগদ মার্চেন্ট পে',
      is_qr_available: 1,
      description_font: 'font-kalpurush',
      description_bn:
        'নগদের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন।',
      description_bn_last_line: 'নগদ মার্চেন্ট একাউন্ট নাম্বারঃ ',
      description_bn_small:
        'নগদের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন।<br>নগদ মার্চেন্ট একাউন্ট নাম্বারঃ',
      description_bn_last_line_small: '01914353693',
      number: '01914353693',
      payment_icon: <NagadQr />,
    },
    {
      name: 'aibl',
      logo: <BankLogo />,
      icon: '/images/bank-icon.png',
      icon2: <BankIcon />,
      icon3: <BankIcon2 />,
      title_bn: 'আল আরাফা ইসলামি ব্যাংক',
      is_qr_available: 0,
      description_font: '',
      description_bn:
        'Al-Arafah Islami Bank Limited.<br>Account No: 1171020010205<br>Account Name: Greentech Apps Bangladesh<br>Branch Name: Kawran Bazar Branch<br>SWIFT Code: ALARBDDH<br>Routing No: 015262538',
      description_bn_last_line: '',
      description_bn_small:
        'Al-Arafah Islami Bank Limited.<br>Account No: 1171020010205<br>Account Name: Greentech Apps Bangladesh<br>Branch Name: Kawran Bazar Branch<br>SWIFT Code: ALARBDDH<br>Routing No: 015262538',
      description_bn_last_line_small: '',
      number: '',
      payment_icon: '',
    },
    {
      name: 'rocket',
      logo: <Rocket />,
      icon: '/images/rocket-icon.png',
      icon2: '',
      icon3: '',
      title_bn: 'রকেট মার্চেন্ট পে',
      is_qr_available: 0,
      description_font: 'font-kalpurush',
      description_bn:
        'রকেটের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন। রেফারেন্স নাম্বার দেয়ার প্রয়োজন নেই, চাইলে 1 দিতে পারেন।',
      description_bn_last_line: 'রকেট মার্চেন্ট একাউন্ট নাম্বারঃ ',
      description_bn_small:
        'রকেটের মাধ্যমে “মার্চেন্ট পে” অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন। রেফারেন্স নাম্বার দেয়ার প্রয়োজন নেই, চাইলে 1 দিতে পারেন।<br>রকেট মার্চেন্ট একাউন্ট নাম্বারঃ',
      description_bn_last_line_small: '01914353593',
      number: '01914353593',
      payment_icon: '',
    },
    {
      name: 'bkash',
      logo: <Bkash />,
      icon: '/images/bkash-icon.png',
      icon2: '',
      icon3: '',
      title_bn: 'বিকাশ পেমেন্ট',
      is_qr_available: 1,
      description_font: 'font-kalpurush',
      description_bn:
        'সংগত কারণে আমরা বিকাশ ব্যবহার অনুৎসাহিত করছি, তারপরও এমন কেউ যদি থাকেন যার বিকাশ ছাড়া অন্য অপশন নেই, তাহলে বিকাশে পাঠাতে পারেন। বিকাশের মাধ্যমে পেমেন্ট অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন।',
      description_bn_last_line: 'বিকাশ মার্চেন্ট একাউন্ট নাম্বারঃ ',
      description_bn_small:
        'বিকাশের মাধ্যমে পেমেন্ট অপশন থেকে নিচের মার্চেন্ট একাউন্টে অনুদান পাঠাতে পারবেন।<br>বিকাশ মার্চেন্ট একাউন্ট নাম্বারঃ',
      description_bn_last_line_small: '01914353593',
      number: '01914353593',
      payment_icon: <BkashQr />,
    },
  ];
  const { addToast } = useToast();
  const [isCopied, setIsCopied] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    donationBdMediums[0],
  );

  const handleCopyLink = async (text) => {
    setIsCopied(true);
    copyToClipboard(text, addToast);
  };

  return (
    <div className="flex flex-col justify-between mt-8 lg:mt-6">
      <div className="flex flex-col lg:grid lg:grid-cols-4 lg:gap-x-4 gap-y-4">
        {donationBdMediums.map((method) => (
          <DonationBdMedium
            key={method.name}
            method={method}
            isCopied={isCopied}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            setShowQR={setShowQR}
            setIsCopied={setIsCopied}
          />
        ))}
      </div>

      {selectedPaymentMethod && (
        <div className="flex-col hidden px-8 pt-8 pb-6 mt-4 rounded-2xl bg-neutral-0 lg:flex">
          {selectedPaymentMethod.name == 'aibl' && (
            <Icon
              className="w-20 h-12 mb-2"
              icon={selectedPaymentMethod.icon2}
            />
          )}

          <p className="max-w-36 font-anek-bangla text-xl leading-[1.6] font-semibold inline-block">
            <span className="border-b border-neutral-2">
              {selectedPaymentMethod.title_bn}
            </span>
          </p>

          <div
            className={`${selectedPaymentMethod.description_font} mt-4 inline-block text-lg leading-[1.78] tracking-[0.2px]`}
            dangerouslySetInnerHTML={{
              __html: selectedPaymentMethod.description_bn,
            }}
          />
          {selectedPaymentMethod.description_bn_last_line && (
            <div className="flex items-center">
              <p className="font-kalpurush text-lg leading-[1.78] tracking-[0.2px]">
                {selectedPaymentMethod.description_bn_last_line}{' '}
                <b>{selectedPaymentMethod.number}</b>
              </p>
              {selectedPaymentMethod.number && (
                <CopyButton
                  text={selectedPaymentMethod.number}
                  isCopied={isCopied}
                  onCopy={handleCopyLink}
                />
              )}
            </div>
          )}

          {selectedPaymentMethod.name == 'bkash' && <BkashPaymentOptions />}

          {selectedPaymentMethod.is_qr_available == 1 && (
            <button
              onClick={() => setShowQR(true)}
              className="inline-flex items-center self-start p-1 px-2 mt-4 border rounded-lg shadow-none hover:shadow-lg bg-neutral-1 border-ash-4"
            >
              <img src="/icons/qr-icon.svg" />
              <p className="ml-1">QR কোড দেখুন</p>
            </button>
          )}
          {showQR && (
            <div className="flex items-center justify-center mt-10 bg-neutral-1">
              <Icon
                className="w-auto h-auto py-4 md:w-auto md:h-auto"
                icon={selectedPaymentMethod.payment_icon}
              />
            </div>
          )}
        </div>
      )}
      <p className="block mt-8 text-xl text-center font-kalpurush">
        বিঃদ্রঃ অনুগ্রহ করে কেউ যাকাত পাঠাবেন না।
      </p>
    </div>
  );
}
