import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../utils';
import { useToast } from '../hooks/useToast';
import Icon from './ui/icon';
import { ChevronDown } from '../custom-icons';
import { motion } from 'framer-motion';
import CopyButton from './ui/CopyButton';
import BkashPaymentOptions from './BkashPaymentOptions';

export function DonationBdMedium({
  selectedPaymentMethod,
  method,
  isCopied,
  setSelectedPaymentMethod,
  setShowQR,
  setIsCopied,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();

  const handleIconClick = (paymentMethod) => {
    if (selectedPaymentMethod != null) {
      if (paymentMethod && paymentMethod.name === selectedPaymentMethod.name) {
        setSelectedPaymentMethod(null);
      } else {
        setSelectedPaymentMethod(paymentMethod);
      }
    } else {
      setSelectedPaymentMethod(paymentMethod);
    }
    setShowQR(false);
    setIsCopied(false);
    setIsOpen(!isOpen);
  };

  const handleCopyLink = async (text) => {
    setIsCopied(true);
    copyToClipboard(text, addToast);
  };

  return (
    <div>
      <div key={method.name}>
        {method.name == 'bkash' && (
          <p className="mt-3 mb-4 font-kalpurush lg:hidden lg:mt-0">
            সংগত কারণে আমরা বিকাশ ব্যবহার অনুৎসাহিত করছি, তারপরও এমন কেউ যদি
            থাকেন যার বিকাশ ছাড়া অন্য অপশন নেই, তাহলে বিকাশে পাঠাতে পারেন।
          </p>
        )}

        <div
          key={method.name}
          onClick={() => handleIconClick(method)}
          className={`h-24 lg:h-44 rounded-2xl flex items-center justify-between lg:justify-center cursor-pointer transition-all
            ${
              selectedPaymentMethod?.name === method.name
                ? ' border-primary bg-primary-0 border-2'
                : 'border border-neutral-2 bg-neutral-0 hover:border-primary '
            }`}
        >
          {method.name === 'aibl' && (
            <div className="w-auto px-4 lg:px-0">
              <Icon
                className="w-auto h-8 px-2 md:w-full md:h-full lg:hidden"
                icon={method.icon3}
              />
              <Icon
                className="hidden w-auto h-10 px-2 lg:block md:w-full md:h-full"
                icon={method.logo}
              />
            </div>
          )}
          {method.name != 'aibl' && (
            <div key={`${method.name}-small`} className="w-auto px-4 lg:px-0">
              <Icon
                className="w-auto h-10 px-2 md:w-full md:h-full"
                icon={method.logo}
              />
            </div>
          )}
          <div className="">
            <motion.div
              animate={{
                rotate: selectedPaymentMethod?.name !== method.name ? 0 : 180,
              }}
              transition={{ duration: 0.3 }}
              className="flex items-center justify-center w-10 h-10 mx-6 rounded-full bg-neutral-1 lg:hidden lg:w-auto lg:h-auto lg:mx-0"
            >
              <ChevronDown className="w-6 h-6" />
            </motion.div>
          </div>
        </div>

        {selectedPaymentMethod?.name === method.name && (
          <div className="flex flex-col px-6 pt-6 pb-6 mt-4 lg:hidden rounded-2xl bg-neutral-0 lg:flex">
            <p className="max-w-36 font-anek-bangla text-xl leading-[1.6] font-semibold inline-block">
              <span className="border-b border-neutral-2">
                {selectedPaymentMethod.title_bn}
              </span>
            </p>

            <div
              className={`${selectedPaymentMethod.description_font} mt-4 inline-block text-lg leading-[1.78] tracking-[0.2px]`}
              dangerouslySetInnerHTML={{
                __html: selectedPaymentMethod.description_bn_small,
              }}
            />
            {selectedPaymentMethod.description_bn_last_line && (
              <div className="flex items-center">
                <p className="font-kalpurush text-lg leading-[1.78] tracking-[0.2px]">
                  <b>{selectedPaymentMethod.description_bn_last_line_small}</b>
                </p>
                {selectedPaymentMethod.number && (
                  <CopyButton
                    text={selectedPaymentMethod.number}
                    isCopied={isCopied}
                    onCopy={handleCopyLink}
                  />
                )}
              </div>
            )}
            {selectedPaymentMethod.name == 'bkash' && (
              <BkashPaymentOptions isMobile={true} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

DonationBdMedium.propTypes = {
  method: PropTypes.object.isRequired,
  isCopied: PropTypes.bool.isRequired,
  selectedPaymentMethod: PropTypes.object,
  setSelectedPaymentMethod: PropTypes.func.isRequired,
  setShowQR: PropTypes.func.isRequired,
  setIsCopied: PropTypes.func.isRequired,
};
