import PropTypes from 'prop-types';
import React from 'react';

const BkashPaymentOptions = ({ isMobile = false }) => {
  const options = [
    {
      amount: '১০০',
      url: 'https://shop.bkash.com/greentech-apps-foundation01914/pay/bdt100/nPiZET',
    },
    {
      amount: '৫০০',
      url: 'https://shop.bkash.com/greentech-apps-foundation01914/pay/bdt500/z5xrnN',
    },
    {
      amount: '১০০০',
      url: 'https://shop.bkash.com/greentech-apps-foundation01914/pay/bdt1000/faD2nc',
    },
  ];

  if (isMobile) {
    return (
      <div className="w-full h-full mt-6">
        <div className="grid w-full h-16 grid-cols-2 gap-x-2">
          <a href={options[0].url} target="_blank" rel="nofollow noreferrer">
            <p className="shadow-none hover:shadow-lg h-full text-xl lg:text-2xl tracking-[0.2px] font-kalpurush border rounded-lg border-neutral-5 flex items-center justify-center">
              {options[0].amount} টাকা
            </p>
          </a>
          <a href={options[1].url} target="_blank" rel="nofollow noreferrer">
            <p className="shadow-none hover:shadow-lg h-full text-xl lg:text-2xl tracking-[0.2px] font-kalpurush border rounded-lg border-neutral-5 flex items-center justify-center">
              {options[1].amount} টাকা
            </p>
          </a>
        </div>
        <div className="grid w-full h-16 grid-cols-2 mt-3 gap-x-2">
          <a href={options[2].url} target="_blank" rel="nofollow noreferrer">
            <p className="shadow-none hover:shadow-lg h-full text-lg lg:text-2xl tracking-[0.2px] font-kalpurush border rounded-lg border-neutral-5 flex items-center justify-center">
              {options[2].amount} টাকা
            </p>
          </a>
          <a
            href="https://shop.bkash.com/greentech-apps-foundation01914/paymentlink"
            target="_blank"
            rel="nofollow noreferrer"
          >
            <p className="shadow-none hover:shadow-lg h-full text-xl lg:text-2xl tracking-[0.2px] font-kalpurush border rounded-lg border-neutral-5 flex items-center justify-center">
              অন্য এমাউন্ট
            </p>
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl">
      <div className="h-20 mt-6 lg:grid lg:grid-cols-3 lg:gap-x-3">
        {options.map((option) => (
          <a
            key={option.amount}
            href={option.url}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <p className="shadow-none hover:shadow-lg h-full text-2xl tracking-[0.2px] font-kalpurush border rounded-lg border-neutral-5 flex items-center justify-center">
              {option.amount} টাকা
            </p>
          </a>
        ))}
      </div>
      <a
        href="https://shop.bkash.com/greentech-apps-foundation01914/paymentlink"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <div className="flex items-center justify-center h-20 mt-6 border rounded-lg shadow-none hover:shadow-lg border-neutral-5">
          <p className="text-2xl tracking-[0.2px] font-kalpurush w-full text-center">
            অন্য এমাউন্ট দান করুন
          </p>
        </div>
      </a>
    </div>
  );
};

export default BkashPaymentOptions;

BkashPaymentOptions.propTypes = {
  isMobile: PropTypes.bool,
};
