import PropTypes from 'prop-types';
import React from 'react';

const CopyButton = ({ text, isCopied, onCopy }) => {
  return (
    <button
      onClick={() => onCopy(text)}
      className="inline-flex items-center px-2 ml-2 border rounded-lg shadow-none hover:shadow-lg t-4 bg-neutral-1 border-ash-4"
    >
      <img
        src={isCopied ? '/icons/tik-icon.svg' : '/icons/copy-icon.svg'}
        className="my-1"
      />
      <p className="my-1 ml-1">{isCopied ? 'কপি হয়েছে' : 'কপি করুন'}</p>
    </button>
  );
};

export default CopyButton;

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  isCopied: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired,
};
