import React from 'react';
import { PropTypes } from 'prop-types';
import { CommonContext } from '../../context';
import Layout from '../../components/layout';

import { DonationBdMediumList } from '../../components/DonationBdList.js';

const DonateFromBDPage = ({ location }) => {
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout title="Support From Bangladesh" location={location} noindex>
        <section className="bg-neutral-1">
          <div className="flex justify-center ">
            <div className="w-full max-w-6xl md:px-0">
              <div className="flex justify-center mt-4 mb-24 md:mt-12">
                <div className="w-full p-4 md:p-0 mx-4">
                  <div className="max-w-4xl ">
                    <h1 className="font-anek-bangla text-2xl lg:text-5xl font-semibold !leading-[1.34] tracking-[0.2px]">
                      বাংলাদেশ থেকেই এখন Greentech Apps কে সাপোর্ট করুন{' '}
                    </h1>
                    <p className="font-kalpurush mt-4 lg:mt-6 text-lg leading-[1.78] tracking-[0.2px]">
                      গ্রীনটেক এপস্‌কে সাপোর্ট করা এখন আরও সহজ। দেশি পেমেন্ট
                      সিস্টেম ব্যবহার করেই আপনার প্রিয় আল-কুরআন, হাদিস, সাদিক
                      আর দু&apos;আ এপের উন্নয়নে শরিক হতে পারবেন।
                      <br />
                      তাহলে আর দেরি কেন? আজ ই এই বরকতময় খেদমতে অংশ নিন, সাদাকা
                      জারিয়ার মাধ্যমে। জাযাকাল্লাহু খইরন।
                    </p>
                  </div>

                  <div>
                    <p className="hidden lg:block mt-12 font-anek-bangla font-semibold text-2xl leading-[1.5]">
                      পেমেন্ট মেথড সমূহ
                    </p>
                    <DonationBdMediumList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </CommonContext.Provider>
  );
};

DonateFromBDPage.propTypes = {
  location: PropTypes.object,
};

export default DonateFromBDPage;
